import * as React from "react"
import { useEffect } from "react"

import Layout from "../components/layout"
import Scroll from "../components/ScrollToTop/Scroll"
import Seo from "../components/seo"
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy"

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Layout>
        <Scroll showBelow={250} />
        <Seo title="Privacy" />
        <PrivacyPolicy />
      </Layout>
    </>
  )
}

export default Privacy
